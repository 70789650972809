import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { serverRoutes } from "../../../constants/serverRoutes";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";

import {
  setListingState,
  resetListingState,
} from "../filters/claimManagerFilters";
import { commonPagination } from "../../../components/common/Utils/utils";

export const ClaimCustomStatusCodeSlice = createSlice({
  name: "ClaimCustomStatusCodeSlice",
  initialState: {
    claimCustomCodeTabularList: [],
    claimCustomCodeDropdownList: [],
  },
  reducers: {
    setClaimCustomCodeTabular_data: (state, action) => {
      let data = action.payload;
      // console.log("data_slice--->>", data);

      if (data?.length) {
        state.claimCustomCodeTabularList = data;
      } else {
        state.claimCustomCodeTabularList = [];
      }
    },

    setClaimCustomCodeDropdown_data: (state, action) => {
      let data = action.payload;
      console.log("data_slice--->>", data);

      if (data?.length) {
        state.claimCustomCodeDropdownList = data;
      } else {
        state.claimCustomCodeDropdownList = [];
      }
    },
  },
});

export const {
  setClaimCustomCodeTabular_data,
  setClaimCustomCodeDropdown_data,
} = ClaimCustomStatusCodeSlice.actions;

// get list
export const gettingClaimCustomStatusCodeList_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      if (clear === true) {
        dispatch(
          resetListingState({
            listing: "claimCustomCodeConfigFilter",
            ignore: ["claimStatus,payers,cptCodes,CARC"],
          })
        );
      }
      if (clear === "resetBtn") {
        dispatch(
          resetListingStateForResetButton({
            listing: "claimCustomCodeConfigFilter",
            ignore: [
              "BillingProviderIds",
              "frequencyType",
              "frequencyType",
              "search",
            ],
          })
        );
      }
    }
    let sliceState = getState().npReducers.filters.claimCustomCodeConfigFilter;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceState?.fetchLatest === true && { timestamp: Date.now() }),
      };
      let params = {
        start,
        limit,
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceState?.fetchLatest === true && { timestamp: Date.now() }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setClaimCustomCodeTabular_data,
        "claimCustomCodeConfigFilter",
        "GET_CLAIM_CUSTOM_STATUS_CODE_LIST",
        false,
        "",
        ""
      );

      ///// API CALL //////
    } catch (e) {
      console.log("e--->>", e);
    }
  };

export const addUpdateClaimCustomStatusCode_Call =
  (obj, method, setSubmitLoading, setNewOpen, setFormValues, setUpdateObj) =>
  async (dispatch, getState) => {
    try {
      // console.log("api_call--->>");
      const route =
        method == "put"
          ? serverRoutes.UPDATE_STATUS_CODE
          : serverRoutes.ADD_STATUS_CODE;

      const res = await genericAxiosCall(route, method, obj, "");

      if (res?.data?.success) {
        const { message } = res?.data;
        toast.success(message, { position: toast.POSITION.TOP_CENTER });
        setNewOpen(false);
        setFormValues({ statusCode: "" });

        dispatch(
          setListingState({
            listing: "claimCustomCodeConfigFilter",
            value: { fetchLatest: true },
          })
        );

        dispatch(gettingClaimCustomStatusCodeList_Call());
        setUpdateObj({});
      } else if (res?.data?.success === false) {
        const { message } = res?.data;
        toast.error(message, { position: toast.POSITION.TOP_CENTER });
      }
    } catch (error) {
      console.log(" --->>", error);
    } finally {
      setSubmitLoading(false);
    }
  };

// delete single client
export const deleteClaimCustomStatusCode_Call =
  (id, setLoadingDeleteClass, setOpenDeleteModal, setUpdateObj, list) =>
  async (dispatch, getState) => {
    try {
      const res = await genericAxiosCall(
        `${serverRoutes.DELETE_STATUS_CODE}=${id}`,
        "delete",
        "",
        ""
      );
      const res2 = await genericAxiosCall(
        `${serverRoutes.RETREIEVEA_ALL_STATUS_CODE_LIST}`,
        "get",
        "",
        ""
      );
      console.log("res2--->>", res2);
      if (res?.data?.success === true) {
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });

        setOpenDeleteModal(false);
        dispatch(
          setListingState({
            listing: "claimCustomCodeConfigFilter",
            value: { fetchLatest: true },
          })
        );

        setUpdateObj({});
        if (list.length === 1) {
          console.log();
          dispatch(
            setListingState({
              listing: "claimCustomCodeConfigFilter",
              value: {
                pageNumber: 1,
              },
            })
          );
        }
        dispatch(gettingClaimCustomStatusCodeList_Call());

        return res;
      }
    } catch (error) {
      console.log("e0--->>", error);
    } finally {
      // console.log("e0--->>", error);
      setLoadingDeleteClass("");
    }
  };

// get list
export const gettingClaimCustomStatusCodeDropdownList_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    try {
      console.log("api--->>");
      let paramsTemp = {};
      let params = {};

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setClaimCustomCodeDropdown_data,
        "claimCustomCodeConfigFilter22",
        "RETREIEVEA_ALL_STATUS_CODE_LIST",
        false,
        "",
        ""
      );

      ///// API CALL //////
    } catch (e) {
      console.log("e--->>", e);
    }
  };

export default ClaimCustomStatusCodeSlice.reducer;
