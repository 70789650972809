import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  Drawer,
  List,
  Collapse,
  CssBaseline,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import ArrowUpMenu from "../../../assets/svgs/ArrowUpMenu";
import ArrowDownMenu from "../../../assets/svgs/ArrowDownMenu";

import browserRoute from "../../../constants/browserRoutes";
import V2ClaimManagerIcon from "../../../assets/svgs/V2ClaimManagerIcon";
import V2OncologyIcon from "../../../assets/svgs/V2OnlogogyIcon";
import V2ReportsIcon from "../../../assets/svgs/V2ReportsIcon";
import V2RemittanceIcon from "../../../assets/svgs/V2RemitanceIcon";
import V2SettingIcon from "../../../assets/svgs/V2SettingIcon";
import V2LeftNavIcon from "../../../assets/svgs/V2LeftNavIcon";
import V2HospitalIcon from "../../../assets/svgs/V2HospitalIcon";

import HeatmapIcon from "../../../assets/svgs/HeatmapIcon";

import { setListingState } from "../../../Redux/Reducers/filters/claimManagerFilters";
import { useDispatch } from "react-redux";
import V2NotificationIcon from "../../../assets/svgs/V2NotificationIcon";
import QueueIcon from "../../../assets/svgs/QueueIcon";

import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import DRAppealIcon from "../../../assets/svgs/DRAppealIcon";
// import BootstrapTooltip from "../TooltipUI/customToolTip";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import DRAvaChatIcon from "../../../assets/svgs/DRAvaChatIcon";
import {
  getterAllBoughtSubscriptionModules,
  getterRoleBasedModules,
  moduleRolesEnum,
  subscriptionPlanNameKey,
} from "../Utils/utils";
import AppLunchar from "./ModualSideDrop";
import { setCurrentModuleRedux } from "../../../Redux/Reducers/UserReducer";
import BootstrapTooltip from "../TooltipUI/customToolTip";
import { environment } from "../../../constants/serverRoutes";
import DRKnowledgeBaseIcon from "../../../assets/svgs/DRKnowledgeBaseIcon";
import DRElgibilityBotIcon from "../../../assets/svgs/DRElgibilityBotIcon";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    placement="right"
    // open={true}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#131722",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight: "400",
    marginLeft: "5px",
  },
}));

const SideBar = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const pathname = location.pathname;
  const { openDrawer } = useSelector(
    (state) => state.npReducers.filters.userPreferencesFilter
  );
  const { currentModule } = useSelector((state) => state.pReducers.user);
  const { appealsDetailsPageFlag } = useSelector(
    (state) => state.npReducers.claimQueue
  );
  const handleSelectModule = (modules) => {
    // console.log("handleSelectModule_1--->>", moduleRoles);

    dispatch(setCurrentModuleRedux(modules));
    setCurrentModuleState(modules);
  };

  // useEffect(() => {
  //   if (location?.pathname === browserRoute?.ASK_ZOE) {
  //     dispatch(
  //       setListingState({
  //         listing: "userPreferencesFilter",
  //         value: {
  //           openDrawer: false,
  //           openDrawerChatbot: false,
  //         },
  //       })
  //     );
  //   }
  // }, [location]);
  const handleSideNavState = () => {
    dispatch(
      setListingState({
        listing: "userPreferencesFilter",
        value: {
          chatbotEpandWidth: false,
        },
      })
    );
    dispatch(
      setListingState({
        listing: "userPreferencesFilter",
        value: {
          openDrawer: !openDrawer,
          openDrawerChatbot: !openDrawer && false,
        },
      })
    );
  };
  const url = pathname.split("/")[1];
  // ===============================================================

  // ===================================  new code start here ===================================

  const { userToken, loginUserData } = useSelector(
    (state) => state.pReducers.user
  );
  const { modules: moduleRoles, roles, OrganizationName } = loginUserData;

  // for handle Role base access

  const [administrator, setAdministrator] = useState(false);
  const [analyst, setAnalyst] = useState(false);
  const [manager, setManager] = useState(false);
  const [executive, setExecutive] = useState(false);
  const [appealRole, setAppealRole] = useState(false);
  const [denialsRole, setDenialsRole] = useState(false);
  const [policyRole, setPolicyRole] = useState(false);

  // for Dropdown collapse

  // below code for Super Admin app side bar collapse
  const [management, setManagement] = useState(false);
  const [support, setSupport] = useState(false);
  const [security, setSecurity] = useState(false);
  const [masterData, setMasterData] = useState(false);

  // below code for user app side bar collapse
  const [insights, setInsights] = useState(false);
  const [denials, setDenials] = useState(false);
  const [claimManager, setClaimManger] = useState(false);
  const [reports, setReports] = useState(false);
  const [remittenceAdvice, setRemittenceAdvice] = useState(false);
  const [onCology, setOnCology] = useState(false);
  const [appeals, setAppeals] = useState(false);
  const [copilot, setCopilot] = useState(false);
  const [sih, setSih] = useState(false);
  const [currentModuleState, setCurrentModuleState] = useState(currentModule);

  const handleDropdown = (dropdownType) => {
    try {
      // handle dropdown for user app

      if (dropdownType == "sih") {
        // console.log("dropdownType__1--->>", dropdownType);

        if (sih) {
          setSih(false);
        } else {
          setSih(!sih);
          setDenials(false);
          setClaimManger(false);
          setReports(false);
          setRemittenceAdvice(false);
          setOnCology(false);
        }
      }
      if (dropdownType == "Insights") {
        // console.log("dropdownType__2--->>", dropdownType);

        if (insights) {
          setInsights(false);
        } else {
          setInsights(!insights);
          setDenials(false);
          setClaimManger(false);
          setReports(false);
          setRemittenceAdvice(false);
          setOnCology(false);
        }
      }
      if (dropdownType == "Reports") {
        // console.log("dropdownType__3--->>", dropdownType);

        if (reports) {
          setReports(false);
        } else {
          setInsights(false);
          setDenials(false);
          setClaimManger(false);
          setReports(!reports);
          setRemittenceAdvice(false);
          setOnCology(false);
        }
      }
      if (dropdownType == "Denials") {
        // console.log("dropdownType__4--->>", dropdownType);

        if (denials) {
          setDenials(false);
        } else {
          setInsights(false);
          setDenials(!denials);
          setClaimManger(false);
          setReports(false);
          setRemittenceAdvice(false);
          setOnCology(false);
        }
      }
      if (dropdownType == "claimManager") {
        // console.log("dropdownType__5--->>", dropdownType);

        if (claimManager) {
          setClaimManger(false);
        } else {
          setInsights(false);
          setDenials(false);
          setClaimManger(!claimManager);
          setReports(false);
          setRemittenceAdvice(false);
          setOnCology(false);
        }
      }

      if (dropdownType == "remittenceAdvice") {
        // console.log("dropdownType__6--->>", dropdownType);

        if (remittenceAdvice) {
          setRemittenceAdvice(false);
        } else {
          setInsights(false);
          setDenials(false);
          setClaimManger(false);
          setReports(false);
          setRemittenceAdvice(!remittenceAdvice);
          setOnCology(false);
        }
      }

      if (dropdownType == "onCology") {
        // console.log("dropdownType__7--->>", dropdownType);

        if (onCology) {
          setOnCology(false);
        } else {
          setInsights(false);
          setDenials(false);
          setClaimManger(false);
          setReports(false);
          setRemittenceAdvice(false);
          setOnCology(!onCology);
        }
      }
      if (dropdownType == "appeals") {
        // console.log("dropdownType__8--->>", dropdownType);

        if (appeals) {
          setAppeals(false);
        } else {
          setSih(false);
          setDenials(false);
          setClaimManger(false);
          setReports(false);
          setRemittenceAdvice(false);
          setOnCology(false);
          setAppeals(!appeals);
        }
      }

      if (dropdownType == "copilot") {
        // console.log("dropdownType__9--->>", dropdownType);

        if (copilot) {
          setCopilot(false);
        } else {
          setSih(false);
          setDenials(false);
          setClaimManger(false);
          setReports(false);
          setRemittenceAdvice(false);
          setOnCology(false);
          setAppeals(false);
          setCopilot(!copilot);
        }
      }

      // handle dropdown for super admin

      if (dropdownType == "management") {
        setManagement(!management);
        setSupport(false);
        setSecurity(false);
        setMasterData(false);
      }

      if (dropdownType == "support") {
        setManagement(false);
        setSupport(!support);
        setSecurity(false);
        setMasterData(false);
      }

      if (dropdownType == "security") {
        setManagement(false);
        setSupport(false);
        setSecurity(!security);
        setMasterData(false);
      }

      if (dropdownType == "masterData") {
        setManagement(false);
        setSupport(false);
        setSecurity(false);
        setMasterData(!masterData);
      }
    } catch (err) {
      console.log("err--->>", err);
    }
  };

  //======= handle Overall System Heat map logic start =============
  const [selDemparment, setSelDepartment] = useState("");

  //======= handle Overall System Heat map logic end =============
  function DenialsModule(props) {
    const { children } = props;
    const userRoles = getterRoleBasedModules();
    return (
      <>
        {denialsRole &&
        userRoles?.find((role) =>
          [moduleRolesEnum?.Denials]?.includes(role?.[subscriptionPlanNameKey])
        )
          ? children
          : null}
      </>
    );
  }
  function AppealsModule(props) {
    const { children } = props;
    const userRoles = getterRoleBasedModules();

    return (
      <>
        {appealRole &&
        userRoles?.find((role) =>
          [moduleRolesEnum?.Appeals]?.includes(role?.[subscriptionPlanNameKey])
        )
          ? children
          : null}
      </>
    );
  }
  function PolicyModule(props) {
    const { children } = props;
    const userRoles = getterRoleBasedModules();

    return (
      <>
        {policyRole &&
        userRoles?.find((role) =>
          [moduleRolesEnum?.Policy]?.includes(role?.[subscriptionPlanNameKey])
        )
          ? children
          : null}
      </>
    );
  }
  function DenialsWrapper(props) {
    const { children } = props;

    return (
      <>
        {currentModuleState?.value?.toLowerCase()?.includes("denials")
          ? children
          : null}
      </>
    );
  }
  function AppealsWrapper(props) {
    const { children } = props;

    return (
      <>
        {currentModuleState?.value?.toLowerCase()?.includes("appeals") ||
        currentModuleState?.value?.toLowerCase()?.includes("denials")
          ? children
          : null}
      </>
    );
  }
  function OnlyAppealsWrapper(props) {
    const { children } = props;

    return (
      <>
        {currentModuleState?.value?.toLowerCase()?.includes("appeals")
          ? children
          : null}
      </>
    );
  }
  function OnlyPolicyWrapper(props) {
    const { children } = props;

    return (
      <>
        {currentModuleState?.value
          ?.toLowerCase()
          ?.includes(moduleRolesEnum?.Policy?.toLocaleLowerCase())
          ? children
          : null}
      </>
    );
  }
  function PolicyWrapper(props) {
    const { children } = props;

    return (
      <>
        {/* {currentModuleState?.toLowerCase()?.includes("policy") ||
        currentModuleState?.toLowerCase()?.includes("denials")
          ? children
          : null} */}
        {children}
      </>
    );
  }
  useEffect(() => {
    let hasUserRole = [];
    let hasModuleRoles = [];
    if (roles?.length) {
      hasUserRole = roles.map((a) => a.name);
    }
    if (moduleRoles?.length) {
      hasModuleRoles = moduleRoles?.map((a) => a.moduleName);
    }
    setAppealRole(hasModuleRoles?.includes(moduleRolesEnum?.Appeals));
    setDenialsRole(hasModuleRoles?.includes(moduleRolesEnum?.Denials));
    setPolicyRole(hasModuleRoles?.includes(moduleRolesEnum?.Policy));
    if (hasUserRole?.length) {
      // console.log("hasUserRole--->>", hasUserRole);

      // Different Roles in Organization Structure for Users
      setAdministrator(hasUserRole.includes("Administrator"));
      setAnalyst(hasUserRole.includes("Analyst"));
      setManager(hasUserRole.includes("Manager"));
      setExecutive(hasUserRole.includes("Executive"));
    }
  }, [userToken, moduleRoles]);
  useEffect(() => {
    setCurrentModuleState(currentModule);
  }, [currentModule]);

  useEffect(() => {
    // execute on location change
    toast.dismiss();

    if (
      pathname === browserRoute?.DOMAIN_COPILOT ||
      pathname === browserRoute?.UPLOAD_DOCOMENTS ||
      pathname === "/ask-zoe" ||
      pathname === "/eligibility" ||
      (pathname === "/ask-zoe") === browserRoute?.ASK_ROVERS
    ) {
      const modules = {
        icon: { type: "img", key: null, ref: null },
        label: "Copilot",
        navLocation: "/ask-zoe",
        value: "Policy",
        wrapper: "Policy",
      };

      // console.log("handleSelectModule_2--->>", modules);

      // dispatch(setCurrentModuleRedux(modules));
      // setCurrentModuleState(modules);
    } else if (
      pathname === browserRoute?.APPEALS_DASHBOARD ||
      url === `${browserRoute?.APPEALS_QUEUE_CLAIM.split("/")[1]}` ||
      url === `${browserRoute?.APPEAL_QUEUE_DETAIL.split("/")[1]}` ||
      url === `${browserRoute?.ATTACHMENTS_PREVIEW.split("/")[1]}` ||
      url === `${browserRoute?.Queue_COMMENTS.split("/")[1]}` ||
      pathname === "/ra" ||
      pathname === "/appeal-queue" ||
      url === "appeal-claim-details" ||
      (url === "queue-comments" && appealsDetailsPageFlag) ||
      url === "attachments" ||
      url === "ra-sl" ||
      // url === "partial" ||
      // url === "rev" ||
      url === "ra-up" ||
      url === "ra-den" ||
      url === "ra-det"
    ) {
      const modules = {
        icon: { type: "img", key: null, ref: null },
        label: "Smart Appeals",
        navLocation: "/appeals-dashboard",
        value: "Appeals",
        wrapper: "Appeals",
      };

      // console.log("handleSelectModule_3--->>", modules);

      // dispatch(setCurrentModuleRedux(modules));
      // setCurrentModuleState(modules);
    } else {
      const modules = {
        icon: { type: "img", key: null, ref: null },
        label: "Denials 360",
        navLocation: "/",
        value: "Denials",
        wrapper: "Denials",
      };

      // console.log("handleSelectModule_4--->>", modules);

      // dispatch(setCurrentModuleRedux(modules));
      // setCurrentModuleState(modules);
    }
  }, [location]);
  return (
    <div
    // className={classes.root}
    >
      <CssBaseline />

      <Drawer
        variant="permanent"
        className={{
          ["DR-new-open"]: openDrawer,
          ["DR-new-close"]: !openDrawer,
        }}
      >
        <div id="Step#2" style={{ height: "100%" }}>
          <div className="DR-v2-New-logo-left">
            {openDrawer ? (
              <>
                <div id="Step#1">
                  <AppLunchar
                    currentModule={currentModuleState}
                    setCurrentModule={setCurrentModuleState}
                    handleSelectModule={handleSelectModule}
                  />
                </div>
              </>
            ) : (
              <>
                <div id="Step#1">
                  {/* {environment === "dev" ? (
                  <img
                    className="Logo-Collapse"
                    width="31px"
                    src={DRLogoDevCollapse}
                    alt="Logo"
                  />
                ) : (
                  <img
                    className="Logo-Collapse"
                    width="31px"
                    src={DRLogoGrCollapse}
                    alt="Logo"
                  />
                )} */}
                  <AppLunchar
                    currentModule={currentModuleState}
                    setCurrentModule={setCurrentModuleState}
                    handleSelectModule={handleSelectModule}
                  />
                </div>
              </>
            )}

            {openDrawer && (
              <p className="DR-logo-name">{currentModuleState?.label}</p>
            )}

            <div className="drawer-toolbar">
              {openDrawer ? (
                <BootstrapTooltip title="Collapse Left Bar">
                  <span>
                    <IconButton
                      style={{
                        padding: "0px",
                        width: "18px",
                        height: "18px",
                        backgroundColor: "#fff",
                      }}
                      onClick={handleSideNavState}
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                    >
                      <V2LeftNavIcon />
                    </IconButton>
                  </span>
                </BootstrapTooltip>
              ) : (
                <BootstrapTooltip title="Expand Left Bar">
                  <span>
                    <IconButton
                      style={{
                        padding: "0px",
                        width: "18px",
                        height: "18px",
                        backgroundColor: "#fff",
                        transform: "rotate(180deg)",
                      }}
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleSideNavState}
                      edge="start"
                    >
                      <V2LeftNavIcon />
                    </IconButton>
                  </span>
                </BootstrapTooltip>
              )}
            </div>
          </div>

          {/* <Divider /> */}
          <List className="DR-new-side-nav">
            <DenialsWrapper>
              {administrator || analyst || manager || executive ? (
                <>
                  {/* Insights */}
                  <DenialsModule>
                    <ListItem
                      className={
                        url ===
                          `${
                            browserRoute?.INSIGHTS_PAYER_DASHBOARD.split("/")[1]
                          }` ||
                        url ===
                          `${
                            browserRoute?.REASON_CODE_TRENDS_FOR_PAYER.split(
                              "/"
                            )[1]
                          }` ||
                        url ===
                          `${
                            browserRoute?.CARC_DISTRIBUTION_ANALYSIS.split(
                              "/"
                            )[1]
                          }` ||
                        url ===
                          `${browserRoute?.DENIAL_OVERVIEW.split("/")[1]}` ||
                        url ===
                          `${browserRoute?.RACR_DASHBOARD.split("/")[1]}` ||
                        url ===
                          `${browserRoute?.PAYER_CPT_ANALYSIS.split("/")[1]}` ||
                        url ===
                          `${
                            browserRoute?.PAYER_DENIAL_DASHBOARD.split("/")[1]
                          }` ||
                        url ===
                          `${
                            browserRoute?.DENIALS_SNAPSHOT_DASHBOARD.split(
                              "/"
                            )[1]
                          }` ||
                        url ===
                          `${browserRoute?.CLAIMS_DASHBOARD.split("/")[1]}` ||
                        url ===
                          `${
                            browserRoute?.DENIALS_BY_PAYER_NEW.split("/")[1]
                          }` ||
                        url ===
                          `${browserRoute?.FACITILY_DENIALS.split("/")[1]}` ||
                        url ===
                          `${
                            browserRoute?.INSIGHTS_SMART_INSIGHTS.split("/")[1]
                          }` ||
                        url === `${browserRoute?.NEW_HOME.split("/")[1]}` ||
                        url === `${browserRoute?.AI_HOME.split("/")[1]}` ||
                        url === `${browserRoute?.APPEAL_HOME.split("/")[1]}` ||
                        url === `${browserRoute?.REASON_CODE.split("/")[1]}` ||
                        url ===
                          `${browserRoute?.PROCEDURE_CODE.split("/")[1]}` ||
                        url ===
                          `${
                            browserRoute?.DR_LANDING_PAGE_TWO.split("/")[1]
                          }` ||
                        url ===
                          `${browserRoute?.PROVIDER_DASHBOARD.split("/")[1]}` ||
                        url ===
                          `${
                            browserRoute?.DEPARTMENTS_DASHBOARD.split("/")[1]
                          }` ||
                        url ===
                          `${browserRoute?.DEPARTMENTS_CPT.split("/")[1]}` ||
                        url ===
                          `${browserRoute?.DEPARTMENTS_RARC.split("/")[1]}` ||
                        url ===
                          `${browserRoute?.DEPARTMENT_DASHBOARD.split("/")[1]}`
                          ? " DR-link-active v3-icon-gre"
                          : "v3-icon"
                      }
                    >
                      <LightTooltip
                        className={openDrawer ? "DR-side-tool" : ""}
                        title={`${OrganizationName ? OrganizationName : ""}`}
                      >
                        <NavLink to={browserRoute?.REACT_SERVER_DEFAULT_URL}>
                          <ListItemIcon>
                            <V2HospitalIcon />
                          </ListItemIcon>
                          <ListItemText>
                            {OrganizationName ? OrganizationName : ""}
                          </ListItemText>
                        </NavLink>
                      </LightTooltip>
                    </ListItem>
                  </DenialsModule>
                </>
              ) : null}
              {administrator || analyst || manager || executive ? (
                <DenialsModule>
                  <ListItem
                    className={
                      url ===
                        `${browserRoute?.REASON_CODE_HEATMAP.split("/")[1]}` ||
                      url === `${browserRoute?.REVENUE_HEATMAP.split("/")[1]}`
                        ? "DR-link-active v3-icon-gre"
                        : "v3-icon"
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <LightTooltip
                      className={openDrawer ? "DR-side-tool" : ""}
                      title="Heatmap"
                    >
                      <NavLink
                        to={browserRoute?.REASON_CODE_HEATMAP}
                        onClick={() => handleDropdown("sih")}
                      >
                        <ListItemIcon>
                          <HeatmapIcon />
                        </ListItemIcon>
                        <ListItemText>Heatmap</ListItemText>
                      </NavLink>
                    </LightTooltip>

                    {openDrawer && (
                      <span
                        className="DR-menu-arrow"
                        onClick={() => handleDropdown("sih")}
                      >
                        {sih ? <ArrowUpMenu /> : <ArrowDownMenu />}
                      </span>
                    )}
                  </ListItem>
                  <Collapse
                    className="DR-hide-nav"
                    in={sih}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      className="DR-new-m-drop"
                      disablePadding
                    >
                      <ListItem
                        className={
                          url ===
                          `${browserRoute?.REASON_CODE_HEATMAP.split("/")[1]}`
                            ? " DR-link-active v3-icon-gre"
                            : "v3-icon"
                        }
                        button
                      >
                        <LightTooltip
                          className={openDrawer ? "DR-side-tool" : ""}
                          title="Denials Heatmap"
                        >
                          <NavLink to={browserRoute?.REASON_CODE_HEATMAP}>
                            <ListItemIcon>
                              <HeatmapIcon />
                            </ListItemIcon>
                            <ListItemText primary="Denials" />
                          </NavLink>
                        </LightTooltip>
                      </ListItem>
                      <ListItem
                        className={
                          url ===
                          `${browserRoute?.REVENUE_HEATMAP.split("/")[1]}`
                            ? " DR-link-active v3-icon-gre"
                            : "v3-icon"
                        }
                        button
                      >
                        <LightTooltip
                          className={openDrawer ? "DR-side-tool" : ""}
                          title="Revenue Heatmap"
                        >
                          <NavLink to={browserRoute?.REVENUE_HEATMAP}>
                            <ListItemIcon>
                              <HeatmapIcon />
                            </ListItemIcon>
                            <ListItemText primary="Revenue" />
                          </NavLink>
                        </LightTooltip>
                      </ListItem>
                    </List>
                  </Collapse>
                </DenialsModule>
              ) : null}

              {administrator || analyst || manager || executive ? (
                <DenialsModule>
                  {/* onCology */}
                  <ListItem
                    className={
                      pathname === "/Oncology" ||
                      url === "oncology" ||
                      url === "onco-accrate" ||
                      url === "onco-accrate-detail" ||
                      url === "pt-repeatden" ||
                      url === "carc-analysis" ||
                      url === "carc-trends" ||
                      url === "onco-accrate" ||
                      url === "Onco-denials" ||
                      url === "carc-trends-dashboard"
                        ? " DR-link-active v3-icon-gre"
                        : "v3-icon"
                    }
                    onClick={() => handleDropdown("onCology")}
                  >
                    <LightTooltip
                      className={openDrawer ? "DR-side-tool" : ""}
                      title="Oncology"
                    >
                      <NavLink to="/oncology">
                        <ListItemIcon>
                          <V2OncologyIcon />
                        </ListItemIcon>
                        <ListItemText>Oncology</ListItemText>
                      </NavLink>
                    </LightTooltip>
                  </ListItem>
                </DenialsModule>
              ) : null}

              {administrator || analyst || manager || executive ? <></> : null}
              {administrator || analyst || manager || executive ? (
                <DenialsModule>
                  <ListItem
                    className={
                      pathname === `${browserRoute?.CLAIM_MANAGER_837}` ||
                      url === `${browserRoute?.CLAIM_ACK.split("/")[1]}` ||
                      url === "Pro-claim-detail" ||
                      url === "claim-detail"
                        ? " DR-link-active v3-icon-gre"
                        : "v3-icon"
                    }
                    onClick={() => handleDropdown("claimManager")}
                  >
                    <LightTooltip
                      className={openDrawer ? "DR-side-tool" : ""}
                      title="Claims"
                    >
                      <NavLink to={`${browserRoute?.CLAIM_MANAGER_837}`}>
                        <ListItemIcon>
                          <V2ClaimManagerIcon />
                        </ListItemIcon>
                        <ListItemText>Claims</ListItemText>
                      </NavLink>
                    </LightTooltip>
                  </ListItem>
                </DenialsModule>
              ) : null}
            </DenialsWrapper>
            <OnlyAppealsWrapper>
              {administrator || analyst || manager || executive ? (
                <AppealsModule>
                  {/* Appeals */}
                  <ListItem
                  // className={
                  //   pathname === "/appeal-queue" ||
                  //   url === "appeal-claim-details" ||
                  //   (url === "queue-comments" && appealsDetailsPageFlag) ||
                  //   url === "attachments"
                  //     ? " DR-link-active v3-icon-gre"
                  //     : "v3-icon"
                  // }
                  // onClick={() => handleDropdown("Reports")}
                  >
                    {/* <LightTooltip
                      className={openDrawer ? "DR-side-tool" : ""}
                      title="Dashboard"
                    >
                      <NavLink to="#">
                        <ListItemIcon>
                          <V2HospitalIcon />
                        </ListItemIcon>
                        <ListItemText>Dashboard</ListItemText>
                      </NavLink>
                    </LightTooltip> */}
                  </ListItem>
                </AppealsModule>
              ) : null}
            </OnlyAppealsWrapper>
            <OnlyAppealsWrapper>
              {administrator || analyst || manager || executive ? (
                <AppealsModule>
                  {/* Appeals */}
                  <ListItem
                    className={
                      pathname === `${browserRoute?.APPEALS_DASHBOARD}`
                        ? " DR-link-active v3-icon-gre"
                        : "v3-icon"
                    }
                  >
                    <LightTooltip
                      className={openDrawer ? "DR-side-tool" : ""}
                      title="Dashboard"
                    >
                      <NavLink to={`${browserRoute?.APPEALS_DASHBOARD}`}>
                        <ListItemIcon>
                          <V2HospitalIcon />
                        </ListItemIcon>
                        <ListItemText>Dashboard</ListItemText>
                      </NavLink>
                    </LightTooltip>
                  </ListItem>
                  <ListItem
                    className={
                      pathname === "/appeal-queue" ||
                      url === "appeal-claim-details" ||
                      (url === "queue-comments" && appealsDetailsPageFlag) ||
                      url === "attachments"
                        ? "DR-link-active v3-icon-gre"
                        : "v3-icon"
                    }
                  >
                    <LightTooltip
                      className={openDrawer ? "DR-side-tool" : ""}
                      title="Appeals"
                    >
                      <NavLink to="/appeal-queue">
                        <ListItemIcon>
                          <DRAppealIcon />
                        </ListItemIcon>
                        <ListItemText>Appeals</ListItemText>
                      </NavLink>
                    </LightTooltip>
                  </ListItem>
                </AppealsModule>
              ) : null}
            </OnlyAppealsWrapper>
            <AppealsWrapper>
              {administrator || analyst || manager || executive ? (
                <>
                  {/* Remittance Advice */}
                  <ListItem
                    className={
                      pathname === "/ra" ||
                      url === "ra-sl" ||
                      // url === "partial" ||
                      // url === "rev" ||
                      url === "ra-up" ||
                      url === "ra-den" ||
                      url === "ra-det"
                        ? " DR-link-active v3-icon-gre"
                        : "v3-icon"
                    }
                    onClick={() => handleDropdown("remittenceAdvice")}
                  >
                    <LightTooltip
                      className={openDrawer ? "DR-side-tool" : ""}
                      title="ERA"
                    >
                      <NavLink to="/ra">
                        <ListItemIcon>
                          <V2RemittanceIcon />
                        </ListItemIcon>
                        <ListItemText>ERA</ListItemText>
                      </NavLink>
                    </LightTooltip>
                  </ListItem>
                </>
              ) : null}
            </AppealsWrapper>
            <DenialsWrapper>
              {administrator || analyst || manager || executive ? (
                <DenialsModule>
                  {/* Reports */}
                  <ListItem
                    className={
                      pathname === "/claim-queue" ||
                      (url === "queue-claim-details" &&
                        !appealsDetailsPageFlag) ||
                      (url === "queue-comments" && !appealsDetailsPageFlag)
                        ? "DR-link-active v3-icon-gre"
                        : "v3-icon"
                    }
                  >
                    <LightTooltip
                      className={openDrawer ? "DR-side-tool" : ""}
                      title="Smart Queues"
                    >
                      <NavLink to="/claim-queue">
                        <ListItemIcon>
                          <QueueIcon />
                        </ListItemIcon>
                        <ListItemText>Smart Queues</ListItemText>
                      </NavLink>
                    </LightTooltip>
                  </ListItem>
                </DenialsModule>
              ) : null}
            </DenialsWrapper>

            {administrator || analyst || manager || executive ? (
              <DenialsWrapper>
                <AppealsModule>
                  <ListItem
                    className={
                      url ===
                        `${browserRoute?.APPEALS_DASHBOARD.split("/")[1]}` ||
                      url ===
                        `${browserRoute?.APPEALS_QUEUE_CLAIM.split("/")[1]}` ||
                      url ===
                        `${browserRoute?.APPEAL_QUEUE_DETAIL.split("/")[1]}` ||
                      url ===
                        `${browserRoute?.ATTACHMENTS_PREVIEW.split("/")[1]}` ||
                      (url ===
                        `${browserRoute?.Queue_COMMENTS.split("/")[1]}` &&
                        appealsDetailsPageFlag)
                        ? "DR-link-active v3-icon-gre"
                        : "v3-icon"
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <LightTooltip
                      className={openDrawer ? "DR-side-tool" : ""}
                      title="Smart Appeals"
                    >
                      <NavLink
                        to={browserRoute?.APPEALS_DASHBOARD}
                        onClick={() => handleDropdown("appeals")}
                      >
                        <ListItemIcon>
                          <DRAppealIcon />
                        </ListItemIcon>
                        <ListItemText>Smart Appeals</ListItemText>
                      </NavLink>
                    </LightTooltip>

                    {openDrawer && (
                      <span
                        className="DR-menu-arrow"
                        onClick={() => handleDropdown("appeals")}
                      >
                        {appeals ? <ArrowUpMenu /> : <ArrowDownMenu />}
                      </span>
                    )}
                  </ListItem>
                  <Collapse
                    className="DR-hide-nav"
                    in={appeals}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      className="DR-new-m-drop"
                      disablePadding
                    >
                      <ListItem
                        className={
                          url ===
                            `${
                              browserRoute?.APPEALS_QUEUE_CLAIM.split("/")[1]
                            }` ||
                          url ===
                            `${
                              browserRoute?.APPEAL_QUEUE_DETAIL.split("/")[1]
                            }` ||
                          url ===
                            `${
                              browserRoute?.ATTACHMENTS_PREVIEW.split("/")[1]
                            }` ||
                          url ===
                            `${browserRoute?.Queue_COMMENTS.split("/")[1]}`
                            ? " DR-link-active v3-icon-gre"
                            : "v3-icon"
                        }
                        button
                      >
                        <LightTooltip
                          className={openDrawer ? "DR-side-tool" : ""}
                          title="Appeal Queues"
                        >
                          <NavLink to="/appeal-queue">
                            <ListItemIcon>
                              <DRAppealIcon />
                            </ListItemIcon>
                            <ListItemText primary="Appeal Queues" />
                          </NavLink>
                        </LightTooltip>
                      </ListItem>
                    </List>
                  </Collapse>
                </AppealsModule>
              </DenialsWrapper>
            ) : null}

            <DenialsWrapper>
              {administrator || analyst || manager || executive ? (
                <>
                  {/* Reports */}
                  <ListItem
                    className={
                      pathname === "/reports-main" ||
                      url === "revenue-by-payer" ||
                      url === "zip" ||
                      url === "denail-payer" ||
                      url === "Service Line Adj" ||
                      url === "claim-aging-report" ||
                      url === "report" ||
                      url === "denials-rep" ||
                      url === "carc-trends-Details"
                        ? " DR-link-active v3-icon-gre"
                        : "v3-icon"
                    }
                    onClick={() => handleDropdown("Reports")}
                  >
                    <LightTooltip
                      className={openDrawer ? "DR-side-tool" : ""}
                      title="Reports"
                    >
                      <NavLink to="/reports-main">
                        <ListItemIcon>
                          {/* <ReportIconNew /> */}
                          <V2ReportsIcon />
                          {/* <span className="V2-icon-cl">
                      <V2ReportsIcon />
                    </span> */}
                          {/* <img className="sim-icon" src={V3ReportIcon} />
                    <img className="act-icon" src={V3ReportIconGre} /> */}
                        </ListItemIcon>
                        <ListItemText>Reports</ListItemText>
                      </NavLink>
                    </LightTooltip>
                    {/* <span className="DR-menu-arrow">
                  {reports ? <ArrowUpMenu /> : <ArrowDownMenu />}
                </span> */}
                  </ListItem>
                </>
              ) : null}
            </DenialsWrapper>
            <OnlyPolicyWrapper>
              {administrator || analyst || manager || executive ? (
                <PolicyModule>
                  {/* Domain Copilot OnlyPolicy */}

                  <>
                    <ListItem
                      className={
                        pathname === browserRoute?.DOMAIN_COPILOT ||
                        pathname === browserRoute?.UPLOAD_DOCOMENTS
                          ? " DR-link-active v3-icon-gre"
                          : "v3-icon"
                      }
                    >
                      <LightTooltip
                        className={openDrawer ? "DR-side-tool" : ""}
                        title="Policy Copilot"
                      >
                        <NavLink
                          to={browserRoute?.DOMAIN_COPILOT}
                          onClick={() => handleDropdown("copilot")}
                        >
                          <ListItemIcon>
                            <DRAvaChatIcon />
                          </ListItemIcon>
                          <ListItemText>
                            {OrganizationName} Copilot
                          </ListItemText>
                        </NavLink>
                      </LightTooltip>
                      {openDrawer && (
                        <span
                          className="DR-menu-arrow"
                          onClick={() => handleDropdown("copilot")}
                        >
                          {copilot ? <ArrowUpMenu /> : <ArrowDownMenu />}
                        </span>
                      )}
                    </ListItem>

                    <Collapse
                      className="DR-hide-nav"
                      in={copilot}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List
                        component="div"
                        className="DR-new-m-drop"
                        disablePadding
                      >
                        <ListItem
                          className={
                            pathname === browserRoute?.UPLOAD_DOCOMENTS
                              ? " DR-link-active v3-icon-gre"
                              : "v3-icon"
                          }
                        >
                          <LightTooltip
                            className={openDrawer ? "DR-side-tool" : ""}
                            title="Knowledge Base"
                          >
                            <NavLink to={browserRoute?.UPLOAD_DOCOMENTS}>
                              <ListItemIcon>
                                <DRKnowledgeBaseIcon />
                              </ListItemIcon>
                              <ListItemText>Knowledge Base</ListItemText>
                            </NavLink>
                          </LightTooltip>
                        </ListItem>
                      </List>
                    </Collapse>
                  </>

                  <ListItem
                    className={
                      pathname === "/ask-zoe"
                        ? " DR-link-active v3-icon-gre"
                        : "v3-icon"
                    }
                  >
                    <LightTooltip
                      className={openDrawer ? "DR-side-tool" : ""}
                      title="Policy Copilot"
                    >
                      <NavLink to="/ask-zoe">
                        <ListItemIcon>
                          <DRAvaChatIcon />
                        </ListItemIcon>
                        <ListItemText>Policy Copilot</ListItemText>
                      </NavLink>
                    </LightTooltip>
                  </ListItem>
                  <ListItem
                    className={
                      pathname === browserRoute?.ASK_ROVERS
                        ? " DR-link-active v3-icon-gre"
                        : "v3-icon"
                    }
                  >
                    <LightTooltip
                      className={openDrawer ? "DR-side-tool" : ""}
                      title="Eligibility Copilot"
                    >
                      <NavLink to={browserRoute?.ASK_ROVERS}>
                        <ListItemIcon>
                          <DRElgibilityBotIcon />
                        </ListItemIcon>
                        <ListItemText>Eligibility Copilot</ListItemText>
                      </NavLink>
                    </LightTooltip>
                  </ListItem>
                </PolicyModule>
              ) : null}
            </OnlyPolicyWrapper>
            <AppealsWrapper>
              {administrator || analyst || manager || executive ? (
                <>
                  {/* Reports */}
                  <ListItem
                    className={
                      pathname === "/alert-center" || url === "alert-details"
                        ? " DR-link-active v3-icon-gre"
                        : "v3-icon"
                    }
                  >
                    <LightTooltip
                      className={openDrawer ? "DR-side-tool" : ""}
                      title="Alert Center"
                    >
                      <NavLink to="/alert-center">
                        <ListItemIcon>
                          <V2NotificationIcon />
                        </ListItemIcon>
                        <ListItemText>Alert Center</ListItemText>
                      </NavLink>
                    </LightTooltip>
                  </ListItem>
                </>
              ) : null}
            </AppealsWrapper>
          </List>
          {administrator ? (
            <List className="DR-new-side-nav Dr-setting-btm">
              <ListItem
                className={
                  pathname === "/settings" ||
                  url === "exception-list" ||
                  url === "sl" ||
                  url === "hs" ||
                  url === "advance" ||
                  url === "sub" ||
                  url === "users" ||
                  url === "activity-log" ||
                  url === "sources" ||
                  url === "logs" ||
                  url === "goals" ||
                  url === "biosim" ||
                  url === "nevo-charts" ||
                  url === "ack" ||
                  url === "partial" ||
                  url === "DragDropLayout" ||
                  url === "graph-details" ||
                  url === "prior-auth" ||
                  url === "mapbox-testing" ||
                  url === "labels" ||
                  url === "queues-bucket" ||
                  url === "login-attempts"
                    ? " DR-link-active v3-icon-gre"
                    : "v3-icon"
                }
              >
                <LightTooltip
                  className={openDrawer ? "DR-side-tool" : ""}
                  title="Settings"
                >
                  <NavLink id="Step#4" to={browserRoute.SETTING_DASHBOARD}>
                    <ListItemIcon>
                      <V2SettingIcon />
                    </ListItemIcon>
                    <ListItemText>Settings</ListItemText>
                  </NavLink>
                </LightTooltip>
              </ListItem>
            </List>
          ) : null}
        </div>
      </Drawer>
    </div>
  );
};

export default SideBar;
